<template>
    <div class="app-container">
        <dd-card
            class="box-card"
            :title="'Filtre'"
            :collapsed.sync="isCollapsed"
            @toggledCard="isCollapsed = !isCollapsed"
        >
            <el-row :gutter="10">
                <el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
                    <div class="sub-title" v-html="$t('payments.billed.invoices-filters-intervalStart')"></div>
                    <el-select
                        class="fullWidth"
                        v-model="filters.intervalStart.value"
                        placeholder="Toate"
                        @change="onIntervalStartChange"
                    >
                        <el-option label="Toate" value=""></el-option>
                        <el-option v-for="item in intervalStartList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
                    <div class="sub-title" v-html="$t('payments.billed.invoices-filters-intervalEnd')"></div>
                    <el-select
                        class="fullWidth"
                        v-model="filters.intervalEnd.value"
                        placeholder="Toate"
                        @change="applyFilters"
                    >
                        <el-option label="Toate" value=""></el-option>
                        <el-option v-for="item in intervalsEndList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
                    <div class="sub-title" v-html="$t('payments.billed.invoices-filters-types')"></div>
                    <el-select
                        class="fullWidth"
                        v-model="filters.invoiceType.value"
                        placeholder="Toate"
                        @change="applyFilters"
                    >
                        <el-option label="Toate" value=""></el-option>
                        <el-option
                            v-for="item in invoiceTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
                    <div class="sub-title">Status</div>
                    <el-select
                        class="fullWidth"
                        v-model="filters.paidStatus.value"
                        placeholder="Toate"
                        @change="applyFilters"
                    >
                        <el-option label="Toate" value=""></el-option>
                        <el-option
                            v-for="item in invoicesPaidFilter"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
                    <div class="sub-title" v-html="$t('payments.billed.invoices-filters-invoiceNr')"></div>
                    <el-input v-model="filters.invoiceNumber.value" class="input-with-select fullWidth">
                        <el-button slot="append" icon="el-icon-search" @click="applyFilters"></el-button>
                    </el-input>
                </el-col>
            </el-row>
        </dd-card>
        <div class="separator"></div>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span v-html="$t('payments.billed.invoices-list')"></span>
            </div>
            <div class="body">
                <el-alert :closable="false" :title="$t('general.warning')" type="warning" show-icon>
                    <div>
                        Facturile pentru avans
                        <strong
                            >(FA) nu necesita plata din partea dumneavoastra. Va rugam sa nu achitati astfel de
                            facturi!</strong
                        >
                    </div>
                </el-alert>

                <div class="center">
                    <el-pagination
                        layout="prev, pager, next, total"
                        :current-page="currentPage"
                        :total="invoicesListCount"
                        :page-size="limit"
                        @current-change="changePage"
                    >
                    </el-pagination>
                </div>
                <el-table :data="invoicesList" style="width: 100%">
                    <el-table-column align="center" prop="id_referinta" label="ID" width="90px"> </el-table-column>
                    <el-table-column
                        align="center"
                        prop="data_facturarii"
                        :label="$t('payments.billed.invoices-invoice-date')"
                    >
                    </el-table-column>
                    <el-table-column align="center" :label="$t('payments.billed.invoices-interval')">
                        <template slot-scope="scope">
                            {{ scope.row.interval_start }} -> {{ scope.row.interval_end }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" :label="$t('payments.billed.invoices-serial-nr')">
                        <template slot-scope="scope"> {{ scope.row.serie }} {{ scope.row.invoice }} </template>
                    </el-table-column>
                    <el-table-column align="center" prop="tip_factura" :label="$t('payments.billed.invoices-type')">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="valoare_factura"
                        :label="$t('payments.billed.invoices-sum')"
                    ></el-table-column>
                    <el-table-column align="center" :label="$t('payments.billed.invoices-paid-sum')">
                        <template slot-scope="scope">
                            <span
                                v-html="
                                    !hasSumaPlatita(scope.row)
                                        ? 'N/A'
                                        : scope.row.suma_platita > scope.row.valoare_factura
                                        ? scope.row.valoare_factura
                                        : scope.row.suma_platita
                                "
                            ></span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="scadenta" :label="$t('payments.billed.invoices-due-date')">
                        <template slot-scope="scope">
                            {{ !hasScadenta(scope.row) ? 'N/A' : scope.row.scadenta }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" :label="$t('payments.billed.compensare')">
                        <template slot-scope="scope">
                            <template v-if="scope.row.tip_factura != 'FC'">
                                <div>N/A</div>
                            </template>
                            <template v-else>
                                <template v-if="scope.row.compensare == 1">
                                    <router-link :to="{ path: '/financiar/compensari' }">
                                        <el-button @click="mergiLaCompensari" type="success" size="mini" plain>{{
                                            $t('payments.billed.compensare')
                                        }}</el-button>
                                    </router-link>
                                </template>
                                <template v-else>
                                    <el-button type="info" size="mini" disabled plain>{{
                                        $t('payments.billed.fara_compensare')
                                    }}</el-button>
                                </template>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Status">
                        <template slot-scope="scope">
                            <svg-icon
                                class="large-bullet"
                                :class="scope.row.achitat == 0 ? 'red' : 'green'"
                                icon-class="primitive-dot"
                            ></svg-icon>
                            <span class="strong">{{
                                scope.row.achitat == 0
                                    ? $t('payments.billed.invoices-unpaid')
                                    : $t('payments.billed.invoices-paid')
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Actiuni" width="130px">
                        <template slot-scope="scope">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="
                                    scope.row.can_download != 1
                                        ? `${$t('payments.billed.can-download-inv-starting')} ${
                                              scope.row.can_download_starting
                                          }`
                                        : $t('payments.billed.bill-dl')
                                "
                                placement="top"
                            >
                                <el-button
                                    :disabled="scope.row.can_download != 1"
                                    :title="
                                        scope.row.can_download != 1
                                            ? $t('payments.billed.invoices-cant-download-inv')
                                            : $t('payments.billed.bill-dl')
                                    "
                                    size="mini"
                                    type="success"
                                    @click="
                                        downloadInvoice(
                                            scope.row.id_referinta,
                                            scope.row.invoice,
                                            scope.row.tip_factura === 'FA' ? 1 : 0
                                        )
                                    "
                                >
                                    <svg-icon icon-class="arrow-down" />
                                </el-button>
                            </el-tooltip>
                            <el-tooltip
                                v-if="scope.row.tip_factura == 'FC'"
                                class="item"
                                effect="dark"
                                :content="$t('payments.billed.invoices-dl-desfasurator')"
                                placement="top"
                            >
                                <el-button
                                    @click="downloadDesfasurator(scope.row.id_referinta, scope.row.invoice)"
                                    :title="$t('payments.billed.invoices-dl-desfasurator')"
                                    size="mini"
                                    type="info"
                                >
                                    <svg-icon icon-class="EXCEL" />
                                </el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="center">
                    <el-pagination
                        layout="prev, pager, next, total"
                        :current-page="currentPage"
                        :total="invoicesListCount"
                        :page-size="limit"
                        @current-change="changePage"
                    >
                    </el-pagination>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import { call, sync } from 'vuex-pathify';
const DDCard = () => import('@/components/DDCard');
import createFinancialStore from '@/store/modules/financial/index.js';
import { createBlob } from '@/utils/index';

export default {
    components: {
        'dd-card': DDCard,
    },
    props: ['filterQuery'],
    data: () => ({
        isCollapsed: true,
        limit: 25,
        filters: {
            intervalStart: {
                type: 'select',
                value: '',
            },
            intervalEnd: {
                type: 'select',
                value: '',
            },
            paidStatus: {
                type: 'select',
                value: '',
            },
            start: {
                type: 'page',
                value: 0,
            },
            // Momentan nu avem tipuri de facturi implementate in intern
            // Asa ca vom returna mereu toate facturile fara sa tinem cont de param asta
            // Lista este hardcodata in store
            invoiceType: {
                type: 'select',
                value: '',
            },
            invoiceNumber: {
                type: 'input',
                value: '',
            },
        },
    }),
    computed: {
        intervalStartList: sync('financial/invoices@intervalsStartList'),
        intervalsEndList: sync('financial/invoices@intervalsEndList'),
        invoiceTypes: sync('financial/invoices@invoiceTypes'),
        invoicesList: sync('financial/invoices@invoicesList'),
        invoicesPaidFilter: sync('financial/invoices@invoicesPaidFilter'),
        invoicesListCount: sync('financial/invoices@invoicesListCount'),
        currentPage() {
            return this.filters.start.value >= this.invoicesListCount
                ? 0
                : parseInt(this.filters.start.value / this.limit) + 1;
        },
    },
    methods: {
        getStartIntervals: call('financial/GetStartIntervals'),
        getEndIntervals: call('financial/GetEndIntervals'),
        getInvoices: call('financial/GetInvoices'),
        actionDownloadInvoice: call('financial/DownloadInvoice'),
        actionDownloadDesfasurator: call('financial/DownloadDesfasurator'),
        async applyFilters() {
            if (typeof this.updateQuery === 'function') this.updateQuery();
            return this.getInvoices(this.filters);
        },
        async onIntervalStartChange(val) {
            try {
                if (val) {
                    await this.getEndIntervals({ start: val });
                }
                await this.applyFilters();
            } catch (err) {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'onIntervalStartChange',
                    },
                });
            }
        },
        async downloadInvoice(refIndex, invoiceNumber, isFA = 0) {
            try {
                const data = await this.actionDownloadInvoice({ refIndex, isFA });
                const blobUrl = createBlob('application/pdf', data);
                const dlnk = document.createElement('a');
                dlnk.href = blobUrl;
                dlnk.setAttribute('target', '_blank');
                dlnk.download = 'factura-' + invoiceNumber + '.pdf';
                document.body.appendChild(dlnk);
                dlnk.click();
            } catch (err) {
                console.error(err);
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'downloadInvoiceInvoices',
                    },
                });
            }
        },
        async downloadDesfasurator(refIndex, invoiceNumber) {
            try {
                const res = await this.actionDownloadDesfasurator(refIndex);
                const blobUrl = createBlob('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', res);
                const dlnk = document.createElement('a');
                dlnk.href = blobUrl;
                dlnk.setAttribute('target', '_blank');
                dlnk.download = 'desfasurator-' + refIndex + '.xlsx';
                document.body.appendChild(dlnk);
                dlnk.click();
            } catch (err) {
                console.error(err);
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'downloadInvoiceInvoices',
                    },
                });
            }
        },
        async changePage(page) {
            this.filters.start.value = page * this.limit - this.limit;
            await this.getInvoices(this.filters);
        },
        hasScadenta(data) {
            return data?.scadenta != null;
        },
        hasSumaPlatita(data) {
            return data?.suma_platita != null;
        },
    },
    watch: {
        'filters.start.value'(val) {},
    },
    beforeCreate() {
        this.$store.registerModule('financial', createFinancialStore());
    },
    async mounted() {
        try {
            await this.getStartIntervals();
            await this.getInvoices(this.filters);
        } catch (err) {
            console.error(err);
            this.$reportToSentry(e, {
                extra: {
                    fn: 'getStartIntervals',
                },
            });
        }
    },
    beforeDestroy() {
        this.$store.unregisterModule('financial');
    },
};
</script>

<style lang="scss" scoped>
.large-bullet {
    position: relative;
    font-size: 24px !important;
    bottom: 2px;

    &.red {
        color: #f56c6c;
    }

    &.green {
        color: #67c23a;
    }
}
.strong {
    font-weight: 700;
    text-transform: uppercase;
}
</style>
