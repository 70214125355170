import actions from './actions';
import gettersFile from './getters';
import mutationsFile from './mutations';
import { make } from 'vuex-pathify';

export const initialState = () => ({
    invoices: {
        intervalsStartList: [],
        intervalsEndList: [],
        invoicesList: [],
        invoicesListCount: 0,
        invoiceTypes: [
            {
                value: 'fc',
                label: 'FC - Factura comisioane',
            },
            {
                value: 'fa',
                label: 'FA - Anexa factura avans',
            },
        ],
        invoicesPaidFilter: [
            { value: '1', label: 'Achitate' },
            { value: '2', label: 'Neachitate' },
        ],
    },
    payments: {
        list: [],
        total: 0,
    },
});

const mutations = {
    ...make.mutations(initialState()),
    ...mutationsFile,
};

const getters = {
    ...make.getters(initialState()),
    ...gettersFile,
};

export default function createFinancialStore() {
    return {
        namespaced: true,
        state: initialState,
        actions,
        getters,
        mutations,
    };
}
