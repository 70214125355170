import store from '@/store/index';
import {
    getStartIntervalsForInvoices,
    getEndIntervalsForInvoices,
    getInvoicesList,
    downloadInvoice,
    downloadXLSBorderou,
    getPayments,
} from '@/api/plati';
export default {
    GetStartIntervals: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getStartIntervalsForInvoices();
                if (res.message == null) return reject(new Error());
                store.set('financial/invoices@intervalsStartList', res.message);
                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetEndIntervals: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getEndIntervalsForInvoices(data);
                if (res.message == null) return reject(new Error());
                store.set('financial/invoices@intervalsEndList', res.message);
                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetInvoices: ({ state }, filters) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getInvoicesList(filters);
                if (res.message == null) return reject(new Error());
                store.set('financial/invoices@invoicesList', res.message.list);
                store.set('financial/invoices@invoicesListCount', res.message.total);
                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    },
    DownloadInvoice: ({ state }, { refIndex, isFA }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await downloadInvoice({ borderou: refIndex, isFA });
                if (res.message == null || res.message.data == null) return reject(new Error());
                resolve(res.message.data);
            } catch (err) {
                return reject(err);
            }
        });
    },
    DownloadDesfasurator: ({ status }, refIndex) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await downloadXLSBorderou({ borderou: refIndex });
                if (res.message == null) return reject(new Error());
                resolve(res.message);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetPayments: ({ state }, filters) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getPayments(filters);
                if (res.message == null) return reject(new Error());
                store.set('financial/payments@list', res.message.list);
                store.set('financial/payments@total', res.message.total);
                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    },
};
